import request from '@/utils/request'
import {
	getToken
} from "@/utils/auth";
// 档案列表
export function profileList(query) {
	return request({
		url: '/admin/agent/profile/profile_list',
		method: 'get',
		params: query
	})
}

//精选档案
export function distProfileList(query) {
	return request({
		url: '/admin/agent/distProfile',
		method: 'get',
		params: query
	})
}

//聊天
export function proChats(query) {
	return request({
		url: '/admin/agent/proChats',
		method: 'get',
		params: query
	})
}



//客服列表
export function expandList(query) {
	return request({
		url: '/admin/agent/profile/expand_list',
		method: 'get',
		params: query
	})
}


//修改档案
export function saveProfile(id, data) {
	return request({
		url: '/admin/agent/profile/saveProfile/' + id,
		method: 'put',
		data
	})
}

//add档案
export function addProfile(data) {
	return request({
		url: '/admin/agent/profile/createProfile',
		method: 'post',
		data
	})
}

// 档案导入
export function profileImport(data) {
	return request({
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': 'Bearer ' + getToken()
		},
		url: '/admin/agent/profile/import',
		method: 'post',
		data: data
	})
}

// 订单列表
export function orderList(query) {
	return request({
		url: '/admin/agent/order/index',
		method: 'get',
		params: query
	})
}


// 订单统计
export function orderCount(query) {
	return request({
		url: '/admin/agent/order/count',
		method: 'get',
		params: query
	})
}

//订单导出
export function orderExport(query) {
	return request({
		headers: {
			'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		},
		responseType: 'blob',
		url: '/admin/agent/order/export',
		method: 'get',
		params: query
	})
}

export function delOrder(id) {
	return request({
		url: `/admin/agent/order/${id}/deleteOrder`,
		method: 'delete',
	})
}


export function examineOrder(data) {
	return request({
		url: `/admin/agent/order/examine`,
		method: 'post',
		data
	})
}


