import request from '@/utils/request'

// 订单管理 - 获取诊疗订单列表
export function getDiaOrderList(query) {
    return request({
        url: '/admin/diagnoses',
        method: 'get',
        params: query
    })
}
// 订单管理 - 获取职称列表
export function getdicts(query) {
    return request({
        url: '/admin/dicts',
        method: 'get',
        params: query
    })
}



//问诊单详情 ---  获取问诊记录  /admin/diagnoses/{diagnosis_id}/chats
export function getDiagnosesChats(query) {
    return request({
        url: "/admin/diagnoses/" + query.diagnosis_id + "/chats",
        method: 'get',
        params: query
    })
}


// // 订单列表 - 获取订单列表  ceshi
// export function getAllList(query) {
//     return request({
//         url: '/admin/pharmacy-orders',
//         method: 'get',
//         params: query
//     })
// }



// 订单管理 - 获取诊疗订单详情   /admin/diagnoses/{diagnosis_id}
export function getDiagOrderInfo(query) {
    return request({
        url: `/admin/diagnoses/${query.diagnosis_id}`,
        method: 'get',
        params: query
    })
}

//订单管理 - 获取处方订单列表  /admin/prescriptions
export function getPresOrderList(query) {
    return request({
        url: '/admin/prescriptions',
        method: 'get',
        params: query
    })
}

// 订单管理 - 获取处方订单详情  /admin/prescriptions/{prescription_id}
export function getPresOrderInfo(query) {
    return request({
        url: "/admin/prescriptions/" + query.prescription_id,
        method: 'get',
        params: query
    })
}


//批量导出   /admin/order-commons-export
export function exportOrders(query) {
    return request({
        headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob',
        url: '/admin/order-commons-export',
        method: 'get',
        params: query
    })
}


//订单管理 - 获取药品订单列表  /admin/order-commons
export function getCommons(query) {
    return request({
        url: '/admin/order-commons',
        method: 'get',
        params: query
    })
}

//订单管理 - 特约订单列表  /admin/specialOrders
export function getSpecialOrders(query) {
    return request({
        url: '/admin/specialOrders',
        method: 'get',
        params: query
    })
}
export function specialOrdersCount(query) {
    return request({
        url: '/admin/specialOrdersCount',
        method: 'get',
        params: query
    })
}
//平台商户列表
export function getMerchants() {
    return request({
        url: '/admin/merchants',
        method: 'get'
    })
}



//订单管理 - 订单发货   /admin/order-commons/{order_common_id}/send
export function sendOrderExpress(data) {
    return request({
        url: `/admin/order-commons/${data.order_common_id}/send`,
        method: 'put',
        data: data
    })
}


//药品订单管理 - 药品订单详情  /admin/order-commons/{order_common_id}
export function getDrugOrderdetail(query) {
    return request({
        url: `/admin/order-commons/${query.order_common_id}`,
        method: 'get',
        params: query
    })
}



//药品订单管理 - 修改订单收货人信息  /admin/order-commons/{order_common_id}/contact
export function changeOrderSHR(query) {
    return request({
        url: `/admin/order-commons/${query.order_common_id}/contact`,
        method: 'put',
        params: query
    })
}

//药品订单管理 - 订单数据统计  /admin/order-commons-summary
export function getmaciesIntro(query) {
    return request({
        url: `/admin/order-commons-summary?order_type=1`,
        method: 'get',
        params: query
    })
}


//药品订单管理 - 订单批量发货  /admin/order-commons-batch-send
export function sendAll(query) {
    return request({
        url: `/admin/order-commons-batch-send`,
        method: 'post',
        data: query
    })
}

//药品订单管理 - 订单备注  /admin/order-commons/{order_common_id}/remark
export function addRemark(query) {
    return request({
        url: `/admin/order-commons/${query.order_common_id}/remark`,
        method: 'put',
        data: query
    })
}



//订单管理 - 获取物流列表  /admin/order/getOrderExpressList
export function getOrderExpressList(query) {
    return request({
        url: '/admin/order/getOrderExpressList',
        method: 'get',
        params: query
    })
}

//处方审核列表   /admin/prescriptions-to-examine
export function getPrescriptions(query) {
    return request({
        url: '/admin/prescriptions-to-examine',
        method: 'get',
        params: query
    })
}

export function getPrescriptionList(query) {
    return request({
        url: '/admin/prescriptions/to-examine',
        method: 'get',
        params: query
    })
}
//处方图片   //admin/order/getDiagPresUrls
export function getDiagPresUrls(query) {
    return request({
        url: '/admin/order/getDiagPresUrls',
        method: 'get',
        params: query
    })
}

// 处方审核   /admin/prescriptions/{:id}/examine
export function prescriptions(params) {
    return request({
        url: '/admin/prescriptions/' + params.id + '/examine',
        method: 'put',
        params: params
    })
}


//  问诊订单监管提交  https://test.hngegejk.cn//diagnoses-batch-supervise
export function pushSupervise(query) {
    return request({
        url: `admin/diagnoses-batch-supervise`,
        method: 'post',
        data: query
    })
}

// //  问诊订单监管提交  https://test.hngegejk.cn//diagnoses-batch-supervise
// export function pushSupervise(query) {
//     return request({
//         url: `admin/diagnoses-batch-supervise`,
//         method: 'post',
//         data: query
//     })
// }


// https://test.hngegejk.cn//admin/prescriptions-batch-supervise
// 处方单批量提交上传监管
export function prescriptionsSupervise(query) {
    return request({
        url: `/admin/prescriptions-batch-supervise`,
        method: 'post',
        data: query
    })
}

// https://test.hngegejk.cn//admin/orders-batch-supervise
// 药品订单批量提交上传监管
export function drugSupervise(query) {
    return request({
        url: `/admin/orders-batch-supervise`,
        method: 'post',
        data: query
    })
}

export function refundOrder(id) {
    return request({
        url: `/admin/order/${id}/refund`,
        method: 'put',
    })
}

//财务支付列表
export function payList(params) {
    return request({
        url: `/admin/finance/payList`,
        method: 'get',
        params
    })
}


//财务支付列表
export function payCount(params) {
    return request({
        url: `/admin/finance/payCount`,
        method: 'get',
        params
    })
}

//财务支付列表
export function exportPayOrder(query) {
    return request({
        headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob',
        url: `/admin/finance/export`,
        method: 'get',
        params: query
    })
}

//修改订单列表
export function adjustOrders(params) {
    return request({
        url: `/admin/adjustOrders`,
        method: 'get',
        params
    })
}

//获取来源选项
export function getOrderSource(type=0) {
    return request({
        url: `/admin/prescriptions/source`,
        method: 'get',
        params:{type}
    })
}


// 特约订单退款
export function putAdminOrderRefund (data) {
  return request({
    url: `admin/order/${data.order}/refund`,
    method: 'PUT',
    data
  })
}
