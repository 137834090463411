<template>
	<div class="gg-container" v-loading="loading">


		<div class="search-container bbox">
			<template v-for="(btn, index) in statusBtn">
				<el-button type="info" size="small" v-if="btn.order_status!=1" class="statusBtn"
					:class="{ btnCurrent: currentStatusIndex == index }" @click="setStatus(index)" :key="index">
					{{ btn.order_status_name }}
					<span v-if="btn.subtotal">（{{ btn.subtotal }}）</span>
				</el-button>
			</template>
		</div>
		<div class="search-gray-bg">
			<div class="search-container">
				<div class="search-container-fn-input">
					<label>订单ID：</label>
					<el-input size="small" placeholder="请输入订单编号" prefix-icon="el-icon-search" clearable
						v-model="searchParams.order_id" @change="getDataList('rest')">
					</el-input>
				</div>
				<div class="search-container-fn-input">
					<label>收货人：</label>
					<el-input size="small" placeholder="收货人姓名/手机号码" prefix-icon="el-icon-search" clearable
						v-model="searchParams.shr_name" @change="getDataList('rest')">
					</el-input>
				</div>

				<!-- <div class="search-container-fn-input">
          <label>平台：</label>
          <el-select
            v-model="searchParams.merchant_id"
            clearable
            placeholder="请选择"
            size="small"
            @change="getDataList('rest')"
          >
            <el-option
              v-for="item in merchants"
              :key="item.merchant_id"
              :label="item.merchant_name"
              :value="item.merchant_id"
            >
            </el-option>
          </el-select>
        </div> -->

				<div class="search-container-fn-input">
					<label>员工：</label>
					<el-input size="small" placeholder="员工" prefix-icon="el-icon-search" clearable
						v-model="searchParams.ad_expand" @change="getDataList('rest')">
					</el-input>
				</div>

				<div class="search-container-fn-input">
					<label>审核状态：</label>
					<el-select v-model="searchParams.examine_status" clearable placeholder="请选择" size="small"
						@change="getDataList('rest')">
						<el-option v-for="item in examineOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>


			</div>


			<div class="search-container">
				<div class="search-container-fn-input">
					<label>快递单：</label>
					<el-input size="small" placeholder="快递单号" prefix-icon="el-icon-search" clearable
						v-model="searchParams.express_no" @change="getDataList('rest')">
					</el-input>
				</div>

				<div class="search-container-fn-input">
					<label>cerp状态:</label>
					<el-select v-model="searchParams.sync_status" clearable placeholder="请选择" size="small"
						@change="getDataList('rest')">
						<el-option v-for="item in syncStatus" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>

				<div class="search-container-fn-input">
					<label>cerp单号：</label>
					<el-input size="small" placeholder="cerp单号" prefix-icon="el-icon-search" clearable
						v-model="searchParams.external_code" @change="getDataList('rest')">
					</el-input>
				</div>

				<div class="search-container-fn-input">
					<label>下单时间：</label>
					<el-date-picker v-model="searchParams.created_at" size="small" type="datetimerange"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="left"
						value-format="yyyy-MM-dd HH:mm:ss" @change="getDataList('rest')">
					</el-date-picker>
				</div>
			</div>
			<div class="search-container">
				<!-- <div class="search-container-fn-input">
          <label>同步时间：</label>
          <el-date-picker
            v-model="searchParams.synced_at"
            size="small"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="getDataList('rest')"
          >
          </el-date-picker>
        </div> -->


				<!-- <div class="search-container-fn-input">
          <label>订单状态：</label>
          <el-select
            v-model="searchParams.status"
            clearable
            placeholder="请选择"
            size="small"
            @change="getDataList('rest')"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
			</div>
		</div>

		<div class="order-count">
			<div style="float:left">
				<span>订单金额:￥{{countData.actual_amount}}</span>
				<span>待收金额:￥{{countData.cash_amount}}</span>
				<span>预付金额:￥{{countData.earn_amount}}</span>
			</div>
			<div style="float:right;margin:30px 20px 0 0">
				<div class="end_bottom">
					<el-button style="padding: 7px 8px;" size="mini" type="success" @click="handleEditOrder('','add')">创建订单</el-button>
					<el-button style="padding: 7px 8px;margin-left: 5px;" size="mini" type="primary" @click="mutilExamine()">批量审核</el-button>

					<el-button style="padding: 7px 8px;margin-left: 5px;" size="mini" type="success" @click="saveExportForm">导出订单</el-button>
				</div>
			</div>
			<div style="clear:both"></div>
		</div>

		<div v-if="agent_pid==0" class="tab-box">
			<button :class="['tab-item',agent_type=='sub'?'active':'']" @click="handleExamineType('sub')">初审</button>
			<button :class="['tab-item',agent_type=='top'?'active':'']" @click="handleExamineType('top')">复审</button>
		</div>
		<el-table :data="tableData" v-loading="listLoading" border style="width: 100%" ref="multipleTable"
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" :selectable="checkboxT" align="center" prop="order_id">
			</el-table-column>
			<el-table-column align="center" prop="common_id" label="订单ID" width="80">
			</el-table-column>
			<el-table-column align="center" prop="pres_id" label="处方ID" width="80">
			</el-table-column>
			<el-table-column align="center" label="商品信息">
				<template slot-scope="{row}">
					<p v-for="(item,index) in row.goods" v-bind:key="index">{{item}}</p>
				</template>
			</el-table-column>
			<el-table-column align="center" label="诊疗信息" width="120">
				<template slot-scope="{row}">
					<el-link type="primary" @click="hanldeDiagInfo(row)">查看诊疗信息</el-link>
				</template>
			</el-table-column>
			<el-table-column align="center" label="预付金额">
				<template slot-scope="{row}">￥{{row.earn_amount}}({{row.earn_pay_status==1?'已收款':'未收款'}})</template>
			</el-table-column>
			<el-table-column align="center" label="待收金额">
				<template slot-scope="{row}">￥{{row.cash_amount}}</template>
			</el-table-column>
			<el-table-column align="center" label="订单金额">
				<template slot-scope="{row}">￥{{row.actual_amount}}(折扣：{{row.discount_amount}})</template>
			</el-table-column>
			<el-table-column align="center" prop="age" label="收货人">
				<template slot-scope="{row}">
					<p>{{row.shr_name}}</p>
					<p>{{row.shr_mobile}}</p>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="shr_address" label="收货地址">

			</el-table-column>

			<el-table-column prop="agent_name" align="center" label="下单平台">

			</el-table-column>
			<el-table-column align="center" prop="staff_name" label="员工信息">
			</el-table-column>
			<el-table-column align="center" prop="order_status_name" label="订单状态">
			</el-table-column>
			<el-table-column align="center" prop="source" label="ECRP同步状态">
				<template slot-scope="{row}">
					<b v-if="row.sync_status == 2">已同步</b>
					<b v-if="row.sync_status == 0">无需同步</b>
					<b v-if="row.sync_status == 1">待同步</b>
					<b v-if="row.sync_status == 3">同步失败</b>
					<p>{{row.external_code}}</p>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="send_at" label="发货时间">
			</el-table-column>
			<el-table-column align="center" prop="express_no" label="快递单号">
			</el-table-column>
			<el-table-column align="center" prop="examine_name" label="审核状态">
				<template slot-scope="{row}">
					<p>{{row.examine_name}}</p>
					<p v-if="row.examine_status == 6">
						<el-tooltip placement="top">
							<div slot="content">
								<p>{{row.reject_reason}}</p>
							</div>
							<el-link type="primary">查看原因</el-link>
						</el-tooltip>
					</p>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="created_at" label="创建时间">
			</el-table-column>

			<el-table-column align="center" prop="pay_amount" label="操作">
				<template slot-scope="{ row }">
					<div class="operation">
						<div>
							<el-link @click="ship(row)" v-if="row.order_status == 2"
								type="primary">发货</el-link>
							<el-link
								v-if="(row.cancel_status==0) && (row.order_status===0||row.order_status===1||((row.order_status===2) && (row.sync_status!=2)))"
								@click="handleEditOrder(row.order_id,'edit')" type="primary">修改订单</el-link>
							<el-link v-if="(row.cancel_status==0) && (row.order_status===0)"
								@click="deleteOrder(row.id)" type="primary">删除订单</el-link>
							<el-link @click="viewDetail(row.common_id)" type="primary">查看详情</el-link>
							<el-link @click="copyPoster(row.cover_img)" type="primary">复制海报</el-link>
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>

		<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
			@pagination="getDataList" />


		<el-dialog :title="examineTitle" :visible.sync="examineDialogVisible" width="770px">
			<div class="order-review-body">
				<el-form label-width="100px" class="demo-ruleForm">
					<el-form-item label="审核结果" prop="status">
						<el-radio-group v-model="status">
							<el-radio label="1">通过</el-radio>
							<el-radio label="0">拒绝</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="拒绝原因" prop="reason" v-if="status == '0'">
						<div v-for="(item1,index) in reasonList" v-bind:key="index">
							<el-radio v-model="reason" :label="item1.value">{{item1.label}}</el-radio>
						</div>
					</el-form-item>
					<el-form-item label="其他原因" prop="reason" v-if="status == '0' && reason == '0'">
						<el-input type="textarea" v-model="other_reason"></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="success" @click="handleExamine">审核</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>



		<!-- <el-dialog
      :title="examineTitle"
      :visible.sync="examineDialogVisible"
      width="30%">
      <div style="display:flex;justify-content: center;margin-top:30px">
        <el-radio v-model="status" label="1" border>同意</el-radio>
        <el-radio v-model="status" label="0" border>拒绝</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="examineDialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="handleExamine">确 定</el-button>
      </span>
    </el-dialog> -->

		<!-- 修改订单 -->
		<div class="modal" v-if="editdialogVisible" @click="handleClose"></div>
		<div class="mobile-box" v-if="editdialogVisible">
			<div class="iframe-mobile">
				<iframe :src="iframeSrc" frameborder="0" width="100%" height="600px"></iframe>
			</div>
		</div>
		<!-- 诊疗信息 -->
		<el-dialog title="诊疗信息" :visible.sync="dialogDiagVisible" width="70%">
			<el-form label-width="80px">
				<el-form-item label="患者姓名">
					{{fast.pat_name}}
				</el-form-item>
				<el-form-item label="患者性别">
					{{fast.pat_gender == 1 ? '男' : '女'}}
				</el-form-item>
				<el-form-item label="患者年龄">
					{{fast.pat_age?fast.pat_age+'岁':''}}
				</el-form-item>
				<el-form-item label="患者电话">
					{{fast.patient_phone}}
				</el-form-item>
				<el-form-item label="既往史">
					{{fast.past_medical}}
				</el-form-item>
				<el-form-item label="过敏史">
					{{fast.allergy_medical}}
				</el-form-item>
				<el-form-item label="病情描述">
					{{fast.desc}}
				</el-form-item>
				<el-form-item label="诊断">
					{{fast.disease_name}}
				</el-form-item>
				<el-form-item label="医生建议">
					{{fast.handle_info}}
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogDiagVisible = false">关闭</el-button>
				<!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
			</span>
		</el-dialog>
		<el-dialog :title="'订单编号：' + currInfo.order_sn" :visible.sync="dialogVisible3" width="425px"
			v-if="dialogVisible3" class="expressDialog">
			<el-form ref="expressForm" :model="currInfo" label-width="120px" :rules="expressFormRules">
				<div v-for="(item, index) in currInfo.bills" :key="index" class="expressIntro">
					<el-form-item label="快递公司：" :prop="'bills.' + index + '.express_company'" :rules="{
		      required: true,
		      message: '请选择快递公司',
		      trigger: 'blur',
		    }">
						<el-select v-model="item.express_company" clearable size="mini" placeholder="请选择">
							<el-option v-for="item in express_company_list" :key="item.val" :label="item.label"
								:value="item.key">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="快递单号：" :prop="'bills.' + index + '.express_no'" :rules="{
		      required: true,
		      message: '快递单号不能为空',
		      trigger: 'blur',
		    }">
						<el-input type="text" size="mini" v-model="item.express_no" placeholder="请输入快递单号"
							show-word-limit maxlength="50"></el-input>
					</el-form-item>
					<i class="el-icon-circle-plus toolIcon text-primary" @click="addExpress" v-if="
		      (index == 0 && currInfo.bills.length < 4) ||
		      (index == 3 && currInfo.bills.length > 3)
		    "></i>
					<i class="el-icon-remove toolIcon text-danger" @click="delExpress(index)" v-if="
		      (index > 0 && currInfo.bills.length < 4) ||
		      (index != 3 && currInfo.bills.length > 3)
		    "></i>
				</div>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" @click="saveForm('expressForm')">确定</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import Pagination from "@/components/Pagination";
	import {
		orderList,
		orderCount,
		orderExport,
		delOrder,
		examineOrder
	} from "@/api/agent";
	import {
		getQNToken
	} from "@/api/basic"; // secondary package based on el-pagination
	import {
		getMerchants,
		sendOrderExpress,
		drugSupervise,
		refundOrder,
	} from "@/api/order";
	import {
		sendAll,
		addRemark
	} from "@/api/order";
	export default {
		name: "order",
		data() {
			return {
				other_reason: '',
				reason: 1,
				fast: {},
				dialogDiagVisible: false,
				examineTitle: '',
				examineDialogVisible: false,
				status: "1",
				agent_type: 'sub',
				agent_pid: '',
				admin_id: '',
				dialogVisible3: false,
				iframeSrc: 'http://h5.hngegejk.cn/#/pages/adjustOrder/adjustOrder?order_id=${order_id}&admin_id=${admin_id}&type=1',
				order_id: '',
				editdialogVisible: false,
				merchants: [],
				reasonList: [{
						label: '患者姓名缺失或姓名错误',
						value: 1
					},
					{
						label: '患者年龄缺失或年龄错误',
						value: 2
					},
					{
						label: '患者性别缺失或性别错误',
						value: 3
					},
					{
						label: '病情描述过于简单无法诊断',
						value: 4
					},
					{
						label: '病情描述没有明确患病时长',
						value: 5
					},
					{
						label: '其他原因',
						value: 0
					},
				],
				syncStatus: [{
						value: 0,
						label: "无需同步",
					},
					{
						value: 1,
						label: "待同步",
					},
					{
						value: 2,
						label: "已同步",
					},
					{
						value: 3,
						label: "同步失败",
					},
				],
				examineOptions: [{
						label: '待审核',
						value: 0
					},
					{
						label: '分部通过',
						value: 1
					},
					{
						label: '总部通过',
						value: 2
					},
					{
						label: '药房通过',
						value: 5
					},

				],
				loading: false,
				Supervise: [{
						value: 1,
						label: "无需上传监管",
					},
					{
						value: 2,
						label: "待上传",
					},
					{
						value: 3,
						label: "已上传",
					},
				],
				allorderList: [],
				del: 0,
				checked: false,
				checkedList: [
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					false,
				],
				isIndeterminate: true,
				checkAll: false,
				currentStatusIndex: 0,
				subtotal: 0,
				statusBtn: [],
				orderList: [],
				datetimerange: "",
				searchParams: {},
				dialogVisible: false,
				dialogVisible2: false,

				dialogVisible4: false,

				actionPath: "http://upload.qiniup.com",
				qiniuData: {
					token: "",
					key: "",
				},
				uploadFile: "",
				uploadFileName: "",

				currInfo: {
					bills: [{
						express_company: "",
						express_no: "",
					}, ],
				},
				openType: "",
				total: 0,
				listLoading: false,
				listQuery: {
					page: 1,
					limit: 20,
					importance: undefined,
					title: undefined,
					type: undefined,
					sort: "+id",
				},
				statusOptions: [
					// 订单状态(0=已取消1=待支付,2=待发货,3=待收货,4=售后中,5已完成）
					{
						value: 0,
						label: "待确认"
					},
					// { value: 1, label: "待支付" },
					{
						value: 2,
						label: "待发货"
					},
					{
						value: 3,
						label: "待收货"
					},
					{
						value: 4,
						label: "售后中"
					},
					{
						value: 5,
						label: "已完成"
					},
					{
						value: 6,
						label: "已取消"
					},
				],
				orderTypeOptions: [
					// 	订单类型(1=普通订单2=特约订单）
					{
						value: 1,
						label: "普通"
					},
					{
						value: 2,
						label: "特约"
					},
				],

				ruleForm: {
					ids: []
				},
				rules: {},
				expressFormRules: {},
				agent_id: '',
				tableData: [],
				countData: [],
				express_company_list: [{
						key: "顺丰快递",
						label: "顺丰快递"
					},
					{
						key: "中通快递",
						label: "中通快递"
					},
					{
						key: "圆通快递",
						label: "圆通快递"
					},
					{
						key: "韵达快递",
						label: "韵达快递"
					},
					{
						key: "申通快递",
						label: "申通快递"
					},
					{
						key: "百世快递",
						label: "百世快递"
					},
					{
						key: "京东快递",
						label: "京东快递"
					},
					{
						key: "邮政小包",
						label: "邮政小包"
					},
					{
						key: "邮政EMS",
						label: "邮政EMS"
					},
				],
				remark: "",
				fieldsList: {
					order_sn: "订单号",
					pay_sn: "支付单号",
					order_from: "订单来源",
					order_status: "订单状态",
					created_at: "下单时间",
					pay_time: "支付时间",
					actual_amount: "实付金额",
					discount_amount: "优惠金额",
					express_fee: "邮费",
					shr_address: "收货地址",
					shr_name: "收货姓名",
					shr_mobile: "收货手机号",
					goods_name: "商品名称",
					spec: "药品规格",
					number: "商品数量",
					goods_amount: "商品金额",
				},
				exportForm: {
					fields: [],
				},
			};
		},
		created() {
			this.admin_id = this.$store.state.user.admin_id

			console.log(111111111111, this.$store.state)
			// this.getMerchantList();
			this.getDataList("rest", true);
			this.getOrderCount()
		},
		computed: {},
		components: {
			Pagination,
		},
		methods: {
			ship(item) {
				console.log('item', item)
				console.log('currInfo', this.currInfo)
				this.currInfo.order_common_id = item.common_id;
				this.currInfo.order_sn = item.order_sn;
				this.currInfo.bills = [{
					express_company: "",
					express_no: "",
				}, ];
				this.dialogVisible3 = true;
			},
			hanldeDiagInfo(row) {
				this.fast = row.fast
				this.dialogDiagVisible = true;
			},
			handleExamineType(type) {
				this.$refs.multipleTable.clearSelection();
				if (type == 'top') {
					this.reason = ''
					this.other_reason = ''
				}
				this.agent_type = type
				// this.getDataList("rest",true);
			},
			copyPoster(cover_img) {
				var a = document.createElement('a');
				var filename = 'poster.jpg';
				a.href = cover_img;
				a.target = "_blank"
				a.download = filename;
				a.click();

			},
			handleExamine() {
				if (this.ruleForm.ids.length == 0) {
					this.examineDialogVisible = false
					return this.$message({
						type: "error",
						message: '请选择订单',
					});
				}
				let reason = ''
				if (this.agent_type == 'sub' && this.reason != 0) {
					reason = this.reasonList.find(v => v.value == this.reason)
					reason = reason ? reason.label : ''
				}
				if (this.status == 0 && this.reason == 0 && !this.other_reason) {
					return this.$message({
						type: "error",
						message: '请填写其他原因',
					});
				}
				reason = reason + this.other_reason
				this.updataExamine(this.ruleForm.ids, this.status, this.agent_type, reason);
			},
			mutilExamine() {

				this.examineTitle = this.agent_type == 'sub' ? '初审' : '复审'
				this.examineDialogVisible = true

			},
			//批量拒绝
			multiReject() {
				this.examineDialogVisible = true;
				// if (this.ruleForm.ids.length == 0) {
				// 		this.$message({
				// 			type: "error",
				// 			message: '请选择订单',
				// 		});
				// 		return;
				// 	}
				// 	this.rejectExamine(this.ruleForm.ids);
			},
			//批量审核通过
			multiPass() {
				this.examineDialogVisible = true;
				// if (this.ruleForm.ids.length == 0) {
				// 	this.$message({
				// 		type: "error",
				// 		message: '请选择订单',
				// 	});
				// 	return;
				// }
				// var type = this.agent_pid==0 ? 'top' : 'sub'
				// this.updataExamine(this.ruleForm.ids,1,type);
			},
			rejectExamine(ids) {
				if (this.ruleForm.ids.length == 0) {
					this.$message({
						type: "error",
						message: '请选择订单',
					});
					return;
				}
				var type = this.agent_pid == 0 ? 'top' : 'sub'
				this.updataExamine(this.ruleForm.ids, 0, type);
			},
			updataExamine(ids, status, agent_type, reason) {
				let params = {
					ids,
					agent_type,
					status,
					reason
				}
				if (this.status == '1') {
					delete params.reason
				}
				examineOrder(params).then(res => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: '操作成功！',
						});
						this.getDataList()
					} else {
						this.$message({
							type: "err",
							message: res.message || res.msg,
						});
					}
				}).catch(err => {
					this.$message({
						type: "err",
						message: err.msg || err.message,
					});
				}).finally(() => {
					this.examineDialogVisible = false
				})
			},
			deleteOrder(id) {
				this.$confirm('确认删除该订单, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delOrder(id).then(res => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.getDataList()
						}
					}).catch(err => {
						console.log(err)
					})

				}).catch((err) => {
					console.log(11, err)
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});

			},
			viewDetail(id) {
				this.$router.push({
					path: "/order/orderDetail",
					query: {
						id
					},
				});
			},
			handleClose() {
				this.getDataList('rest')
				this.editdialogVisible = false
			},
			handleEditOrder(id, type) {
				if (type == 'add') {
					this.iframeSrc =
						`http://cdm_h5.hngegejk.cn/#/pages/orderDetail/orderDetail?type=1&agent_id=${this.agent_id}`
				} else if (type == 'edit') {
					this.iframeSrc =
						`http://cdm_h5.hngegejk.cn/#/pages/adjustOrder/adjustOrder?order_id=${id}&admin_id=${this.admin_id}&type=1`
				}
				this.editdialogVisible = true
			},
			getOrderCount() {
				orderCount().then(res => {
					this.statusBtn = res.data.list;
					// let num = 0;
					// for (let item of this.statusBtn) {
					//   num += item.subtotal;
					// }
				})
			},
			checkboxT(row, rowIndex) {
				console.log('rowIndex', row, rowIndex)
				if (row.cancel_status == 1) {
					return false;
				}
				if (this.agent_pid == 0) {
					if (this.agent_type == 'sub') {
						return (row.fast_type == '0' || row.fast_type == -1 || (row.fast_type == 3 && row.examine_status ==
							'7') || row.examine_status == '4');
					} else {
						return (((row.pres_status == 0 && row.examine_status == '1') || (row.pres_status == 1 && row
							.examine_status == '3')) && row.fast_type == 3)
					}
				} else {
					return (row.fast_type == '0' || row.fast_type == -1 || (row.fast_type == 3 && row.examine_status ==
						'7'))
				}
			},
			handleSelectionChange(val) {
				// this.ruleForm.ids;
				console.log(val);
				console.log(112, val.map(item => item.common_id))
				this.ruleForm.ids = val.map(item => item.common_id)
			},
			refund(item) {
				this.$confirm("确定退款, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						refundOrder(item.order_common_id).then((res) => {
							if (res.code == 200) {
								this.getDataList();
								this.loadIntro();
								setTimeout(() => {
									this.$message({
										type: "success",
										message: "退款成功!",
									});
								}, 500);
							} else {
								this.$message({
									type: "error",
									message: res.msg,
								});
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消",
						});
					});
			},
			getMerchantList() {
				getMerchants().then((res) => {
					if (res.code == 200) {
						this.merchants = res.data;
					}
				});
			},
			push_Supervise() {
				if (this.orderList.length <= 0) {
					this.$message({
						type: "error",
						message: "请选择需要上传的订单！",
					});
				} else {
					let params = {};
					params.orders = [...this.orderList];
					drugSupervise(params).then((res) => {
						if (res && res.code == 200) {
							this.$message({
								type: "success",
								message: "上传成功",
							});
						} else {
							this.$message({
								type: "error",
								message: res.message,
							});
						}
					});
				}
			},
			// getvalue(value) {
			//   console.log(value);
			//   console.log(this.orderList);

			// },
			allcheck(e) {
				if (e == true) {
					let dislist = [];
					let truelist = [];
					let list = this.$refs.domvalue;
					console.log(list);
					for (let i = 0; i < list.length; i++) {
						if (list[i].disabled == false) {
							truelist.push(i);
						}
					}
					for (let i = 0; i < list.length; i++) {
						if (list[i].disabled == true) {
							dislist.push(i);
						}
					}
					for (let i = 0; i < this.checkedList.length; i++) {
						this.checkedList[i] = true;
					}
					for (let k = 0; k < dislist.length; k++) {
						this.checkedList[dislist[k]] = false;
					}
					for (let i = 0; i < truelist.length; i++) {
						this.orderList.push(this.allorderList[truelist[i]]);
					}
					console.log(this.orderList);
				}
				if (e == false) {
					this.checked = false;
					for (let i = 0; i < this.checkedList.length; i++) {
						this.checkedList[i] = false;
					}
					this.orderList = [];
					console.log(this.orderList);
				}
			},
			pushvalue(value, index) {
				console.log(value);
				if (this.checkedList[index] == true) {
					this.orderList.push(value);
					console.log(this.orderList);
				}
				if (this.checkedList[index] == false) {
					let k = this.orderList.indexOf(value);
					if (k != -1) {
						this.orderList.splice(k, 1);
						console.log(this.orderList);
					}
				}
			},



			setStatus(index) {
				let item = this.statusBtn[index];
				this.currentStatusIndex = index;
				this.searchParams.order_status = item.order_status;
				this.getDataList("rest");
			},

			getDataList(rest, isOnload = false) {
				this.loading = true;
				if (rest == "rest") {
					console.log("rest");
					this.listQuery.page = 1;
				}
				this.listLoading = true;
				let params = {
					...this.searchParams
				};
				params.page = this.listQuery.page;
				params.page_size = this.listQuery.limit;
				params.agent_type = this.agent_type

				orderList(params)
					.then((response) => {
						this.loading = false;
						let data = response;
						if (data.code == 200) {
							this.checked = false;
							let _data = data.data;
							this.total = _data.total;
							if (isOnload) {
								let obj = {
									order_status: "",
									order_status_name: "全部订单",
									subtotal: this.total,
								};
								this.statusBtn.unshift(obj);
							}

							this.listQuery.limit = Number(_data.per_page);
							this.tableData = _data.list;
							this.countData = _data.count;
							this.agent_id = _data.agent_id
							this.agent_pid = _data.agent_pid
							this.orderList = [];
							console.log(response);
							this.allorderList = [];
							for (let i = 0; i < response.data.list.length; i++) {
								this.allorderList.push(response.data.list[i].order_common_id);
							}
							console.log(this.allorderList);
						}
						this.listLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.listLoading = false;
					});
			},

			reset() {
				this.searchParams = {};
				this.listQuery.page = 1;
			},

			setRemark(item) {
				this.currInfo = item;
				this.dialogVisible2 = true;
				this.remark = "";
			},
			saveRemark() {
				if (!this.remark) {
					this.$message({
						message: "请填写备注信息",
					});
					return;
				}
				let params = {};
				params.order_common_id = this.currInfo.order_common_id;
				params.remark = this.remark;
				addRemark(params)
					.then((response) => {
						let data = response;
						if (data.code == 200) {
							this.$message({
								type: "success",
								message: "备注添加成功",
							});
							this.dialogVisible2 = false;
						} else {
							this.$message({
								type: "error",
								message: data.msg,
							});
						}
					})
					.catch((error) => {
						console.log(error);
					});
			},
			// 监听日期选择
			dateChange(val) {
				if (val && val.length) {
					this.searchParams.start_time = val[0];
					this.searchParams.end_time = val[1];
				} else {
					this.searchParams.start_time = "";
					this.searchParams.end_time = "";
				}
				this.getDataList("rest");
			},
			addExpress() {
				if (this.currInfo.bills.length == 5) {
					this.$message({
						type: "error",
						message: "最多可以添加5条快递单信息，如果有更多请使用批量发货",
					});
					return;
				}
				this.currInfo.bills.push({
					express_company: "",
					express_no: "",
				});
			},
			delExpress(index) {
				this.currInfo.bills.splice(index, 1);
			},
			saveForm(form) {
				this.$refs[form].validate((valid) => {
					if (!valid) {
						return;
					} else {
						let params = this.currInfo;
						sendOrderExpress(params)
							.then((response) => {
								let data = response;
								if (data.code == 200) {
									this.$message({
										type: "success",
										message: "物流信息添加成功",
									});
									this.getDataList("rest", true);
									this.dialogVisible3 = false;
								} else {
									this.$message({
										type: "error",
										message: data.msg,
									});
								}
							})
							.catch((error) => {
								console.log(error);
							});
					}
				});
			},
			saveExportForm() {

				this.loading = true

				let params = {
					fields: {},
					...this.searchParams,
				};
				// eslint-disable-next-line no-debugger

				if (this.exportForm.fields.length > 0) {
					params.fields = this.exportForm.fields.join(",");
				}
				params.order_type = 2;
				console.log(params);

				orderExport(params)
					.then((response) => {
						let data = response;
						if (data) {
							const xlsx = "application/vnd.ms-excel";
							const blob = new Blob([data], {
								type: xlsx
							});
							const a = document.createElement("a"); // 转换完成，创建一个a标签用于下载
							a.download = "订单.xlsx";
							a.href = window.URL.createObjectURL(blob);
							a.click();
							a.remove();
						} else {
							this.$message({
								type: "error",
								message: data.msg,
							});
						}

					})
					.catch((error) => {
						console.log(error);
					}).finally(() => {
						this.loading = false
					});
			},


			plfh() {
				this.dialogVisible4 = true;
				this.uploadFile = "";
				this.uploadFileName = "";
			},
			exportFiles(file) {
				if (!this.uploadFile) {
					this.$message({
						type: "error",
						message: "请先上传Excel文件",
					});
					return;
				}

				const data = new FormData();
				const fileUps = file.file;
				data.append("file", fileUps);
				sendAll(data)
					.then((response) => {
						let data = response;
						if (data.code == 200) {
							this.$message({
								type: "info",
								message: `批量导入成功${data.data.success}条，失败${data.data.fail}条`,
							});
							this.dialogVisible2 = false;
						} else {
							this.$message({
								type: "error",
								message: data.msg,
							});
						}
					})
					.catch((error) => {
						console.log(error);
					});
			},
		},
	};
</script>

<style scoped lang="scss">
	.tab-box {
		display: flex;
		margin-bottom: 10px;

		.tab-item {
			cursor: pointer;
			padding: 8px 26px;
			border: 1px solid #e5e5e5;
		}

		.tab-item:nth-of-type(1) {
			border-right: none;
		}

		.active {
			background: #1890ff;
			color: #ffffff;

		}
	}

	.order-count {
		color: red;
		font-size: 16px;
		font-size: bold;

		span {
			padding: 10px 25px;
			text-align: center;
			border: 1px solid #e5e5e5;
			border-radius: 10px;
			display: inline-block;
			margin: 20px;
			margin-left: 0;
		}
	}

	.dot {
		display: inline-block;
		border: 5px solid;
		border-radius: 50%;
		margin: 0 5px;
	}

	.tableWrap {
		width: 100%;
		overflow: scroll;
	}

	.orderTable {
		border-color: #e6ebf5 !important;
		overflow-x: hidden;
		width: 2000px;
		border-collapse: collapse;
		font-size: 14px;

		thead {
			th {
				// background-color: #2f4256;
				// border-right-color: #2f4256;
				// color: #fff;
				padding: 10px 0;
				font-weight: bold;
				font-size: 13px;

				&:nth-child(1) {
					border-radius: 3px 0 0 0;
				}

				&:nth-last-child(1) {
					border-radius: 0 3px 0 0;
				}
			}
		}

		tbody {
			.seat {
				background-color: #fff;

				td {
					border: none;
					height: 8px;
				}
			}

			.tb-head {
				td {
					padding: 0 13px;
					background: #f2f8f8;
					border-radius: 3px 3px 0 0;
				}
			}

			.tb-good {
				td {
					padding: 13px;
					padding-bottom: 17px;

					// background: #f2f8f8;
					p {
						margin: 0;
					}
				}
			}

			tr:nth-last-child {
				td {
					&:nth-child(1) {
						border-radius: 0 0 0 30px;
					}

					&:nth-last-child(1) {
						border-radius: 0 0 3px 0;
					}
				}
			}
		}
	}

	.imgBox {
		position: relative;
		text-align: center;
		width: 88px;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);

		.isPres {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 48px;
			height: 30px;
			text-align: right;
			color: #fff;
			background: url("../../assets/isPres.png") no-repeat;
			background-size: contain;
			padding-top: 10px;
		}
	}

	.search-tools {
		font-weight: bold;
		margin-top: 8px;
	}

	.search-gray-bg {
		background: #fafafa;
		border-radius: 3px;
		padding: 17px 13px 5px;

		.search-container {
			margin-bottom: 12px;
		}
	}

	.statusBtn {
		border: 1px solid #e4e4e4;
		background: #fff;
		padding: 8px 13px;
		margin-right: 7px;
		color: #333;
	}

	.btnCurrent {
		background: #0091ff;
		border-color: #0091ff;
		color: #fff;
	}

	.shr {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	.el-date-editor--daterange.el-input,
	.el-date-editor--daterange.el-input__inner,
	.el-date-editor--timerange.el-input,
	.el-date-editor--timerange.el-input__inner {
		width: 220px;
	}

	.shrIntro {
		background: #f0f1f5;

		p {
			margin: 0;
			color: #333;
			font-size: 12px;

			.gray {
				color: #666;
			}
		}
	}

	.expressIntro {
		background: #f2f8f8;
		border-radius: 3px;
		padding: 20px 40px 20px 0;
		position: relative;
		margin-top: 20px;

		.el-form-item {
			margin-bottom: 5px;
		}

		.el-form-item__error {
			padding-top: 0;
		}

		.el-select {
			width: 100%;
		}

		.toolIcon {
			font-size: 24px;
			position: absolute;
			top: -12px;
			right: -12px;
		}
	}

	.uploadExcel {
		font-size: 16px;

		a {
			display: block;
			width: 145px;
			height: 40px;
			border: 1px solid #e4e4e4;
			border-right: none;
			color: #666;
			text-align: center;
			line-height: 40px;
			flex-shrink: 0;
		}

		.fileName {
			flex: 1;
			background: #f1f2f6;
			height: 40px;
			line-height: 40px;
			padding: 0 5px;
		}

		.uploadBtn {
			width: 100px;
			height: 40px;
			border: 1px solid #e4e4e4;
			border-left: none;
			color: #666;
			border-radius: 0;
			font-size: 16px;

			&:hover {
				background: transparent;
			}
		}
	}

	.border-top {
		border-top: 1px solid #e4e4e4;
	}
</style>
<style lang="scss">
	.shrPoper {
		background: #f0f1f5;
	}

	.el-popper[x-placement^="right"] .popper__arrow::after {
		border-right-color: #f0f1f5;
	}

	.el-dialog__header {
		border-bottom: 1px solid #f0f0f0;
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #333;
	}

	.expressDialog {
		.el-dialog__body {
			max-height: 400px;
			overflow: auto;
		}
	}

	.end_bottom {
		display: flex;
	}


	.modal {
		position: fixed;
		width: 100%;
		height: 100%;
		background: #000000;
		opacity: .4;
		top: 0;
		left: 0;
		z-index: 999;
	}

	.mobile-box {
		position: fixed;
		left: 50%;
		top: 50%;
		margin-left: -180px;
		margin-top: -350px;
		z-index: 1000;
		width: 350px;
		height: 700px;
		background: transparent url(https://pic.hngegejk.cn/1622429757694_mobile_20210531105529.png) center center;
		background-size: 350px 700px;

		.iframe-mobile {
			border-radius: 20px;
			width: 300px;
			margin: 0 auto;
			margin-top: 55px;
			height: 700px;
		}
	}
</style>
